import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import jwt_decode from "jwt-decode";
import { AxiosRequestConfig } from "axios";

export interface User {
  id: number;
  email: string;
  emailconfirmed: boolean;
  nick: string;
  firstname: string;
  lastname: string;
  phone: string;
  proofoflifeinterval: number;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  access: string[];
  username: string;
  email: string;
  role: string;
  isAuthenticated: boolean;
  status: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  username = "";
  email = "";
  role = "";
  access = [];
  isAuthenticated = !!JwtService.getToken();
  status = "";

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get currentAccess(): string[] {
    return this.access;
  }

  get currentUsername(): string {
    return this.username;
  }

  get currentEmail(): string {
    return this.email;
  }

  get currentRole(): string {
    return this.role;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get currentStatus(): string {
    return this.status;
  }
  /**
   * Get authentication errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    const decodedToken = jwt_decode(data.jwt);
    const claim = JSON.parse(JSON.stringify(decodedToken));
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = {};
    this.status = claim["status"];
    JwtService.saveToken(data.jwt);
  }

  @Mutation
  [Mutations.SET_PERMISSION](jwt: string) {
    const decodedToken = jwt_decode(jwt);
    const claim = JSON.parse(JSON.stringify(decodedToken));
    this.access = claim["access"];
    this.username = claim["username"];
    this.email = claim["sub"];
    this.role = claim["role"];
    this.status = claim["status"];
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD]() {
    // this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    this.status = "";
    JwtService.destroyToken();
    ApiService.setEmptyHeader();
  }

  @Action
  [Actions.LOGIN](credentials: AxiosRequestConfig) {
    return ApiService.post("api/v1/auth/login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, {
          0: "Authentication failed",
        });
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, { 0: response.data.error });
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, { 0: response.data.error });
      });
  }

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH]() {
    const jwt = window.localStorage.getItem("id_token");
    this.context.commit(Mutations.SET_PERMISSION, jwt);
  }

  @Action
  [Actions.RENEW_AUTH]() {
    const jwt = JwtService.getDecodedToken();
    if (jwt !== null) {
      const date = new Date(jwt["exp"] * 1000);
      const twoDaysFromNow = new Date();
      twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);
      if (twoDaysFromNow >= date) {
        ApiService.get(`/api/v1/auth/verify`)
          .then(({ data }) => {
            this.context.commit(Mutations.SET_AUTH, data);
          })
          .catch((response) => {
            this.context.commit(Mutations.SET_ERROR, {
              0: response.data.error,
            });
          });
      }
    }
  }

  @Action
  [Actions.RENEW_JWT](data) {
    this.context.commit(Mutations.SET_AUTH, data);
  }
}
