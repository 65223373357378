import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

interface ClientSummary {
  numberOfDataEntry: number;
  numberOfAwaitingPayment: number;
  numberOfDeviceConfig: number;
  numberOfAwaitingDispatch: number;
  numberOfDataEntryFacility: number;
  numberOfAwaitingPaymentFacility: number;
  numberOfDeviceConfigFacility: number;
  numberOfAwaitingDispatchFacility: number;
}

interface CustomerSummary {
  numberOfOverdue: number;
  numberOfFailedTransaction: number;
  numberOfCreditCard: number;
}
interface StoreInfo {
  numberOfClients: ClientSummary;
  numberOfCustomers: CustomerSummary;
}

@Module
export default class MenuModule extends VuexModule implements StoreInfo {
  numberOfClients = {
    numberOfDataEntry: 0,
    numberOfAwaitingPayment: 0,
    numberOfDeviceConfig: 0,
    numberOfAwaitingDispatch: 0,
    numberOfDataEntryFacility: 0,
    numberOfAwaitingPaymentFacility: 0,
    numberOfDeviceConfigFacility: 0,
    numberOfAwaitingDispatchFacility: 0,
  };
  numberOfCustomers = {
    numberOfOverdue: 0,
    numberOfFailedTransaction: 0,
    numberOfCreditCard: 0,
  };

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get numberOfGroupedClient() {
    return this.numberOfClients;
  }
  get numberOfGroupedCustomer() {
    return this.numberOfCustomers;
  }
  @Mutation
  [Mutations.SET_NUMBER_OF_CLIENTS](payload) {
    this.numberOfClients = payload;
  }

  @Mutation
  [Mutations.SET_NUMBER_OF_CUSTOMERS](payload) {
    this.numberOfCustomers = payload;
  }

  @Action({ rawError: true })
  async [Actions.GET_NUMBER_OF_CLIENTS]() {
    await ApiService.get(
      `api/v1/bender/provisioning/resident/number-of-clients`
    )
      .then((res) => {
        this.context.commit(Mutations.SET_NUMBER_OF_CLIENTS, res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  @Action({ rawError: true })
  async [Actions.GET_NUMBER_OF_CUSTOMERS]() {
    await ApiService.get(
      `api/v1/bender/provisioning/customer/billing/number-of-customers`
    )
      .then((res) => {
        this.context.commit(Mutations.SET_NUMBER_OF_CUSTOMERS, res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
