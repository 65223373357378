import jwt_decode from "jwt-decode";

const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getDecodedToken = (): string | null => {
  const jwt = window.localStorage.getItem(ID_TOKEN_KEY);
  if (jwt !== null) {
    try {
      return jwt_decode(jwt);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, getDecodedToken };
